import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import RoutesService from '@/services/whatsapp/routes.service'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import AddNewWaba from './AddNewWaba/AddNewWaba.vue'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'Wabas',
  components: {
    HeaderTopDashboard,
    AddNewWaba,
  },
  data () {
    return {
      fbManagerIds: [],
      selectedFbManagerId: null,
      wabas: [],
      newWaba: {
        show: false,
      },
    }
  },
  computed: {
    headers () {
      return [
        { text: 'ID WABA', align: 'start', value: 'waba_id' },
        {
          text: this.$t('Nombre'),
          value: 'waba_name',
          sortable: false,
        },
        {
          text: this.$t('Verificación'),
          value: 'fb_account_status',
          sortable: false,
        },
        {
          text: this.$t('Identificador del espacio'),
          value: 'template_namespace',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          routes.length
            ? this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })
  },
  mounted () {
    RoutesService.getAllFacebookManagerId().then((response) => {
      this.fbManagerIds = response
      this.selectedFbManagerId = this.fbManagerIds[0]
      this.getByFacebookManagerId()
    })
  },
  methods: {
    viewWabaTemplates (wabaId) {
      EventBus.$emit('showMultimedia', 'whatsapp', true)
      setTimeout(() => {
        EventBus.$emit('MultimediaManager::setWabaId', wabaId)
      }, 1200)
    },
    getByFacebookManagerId () {
      RoutesService.getByFacebookManagerId({
        facebookBusinessManagerId: this.selectedFbManagerId.facebook_business_manager_id,
      }).then((response) => {
        this.wabas = response
      })
    },
    openAddNewWaba () {
      this.newWaba.show = true
    },
  },
}
